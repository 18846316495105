/* .search-container {
    font-family: Arial, sans-serif;
  }
  
  input[type="text"] {
    width: 300px;
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    margin: 5px;
    background-color: #0078d4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #005ea6;
  }
  
  .suggestions {
    margin-top: 10px;
  }
  
  .suggestion-item {
    padding: 5px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .result-item {
    margin-bottom: 10px;
  }
  
  .result-item a {
    color: #0078d4;
    text-decoration: none;
  }
  
  .result-item a:hover {
    text-decoration: underline;
  }
  
  .result-item p {
    color: #666;
  } */
  /* .search-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 8px;
    font-size: 16px;
  }
  
  .suggestions-dropdown {
    position: absolute;
    width: 100%;
    background: white;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border: 1px solid #ccc;
    z-index: 1000;
  }
  
  .suggestion-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }
  
  .suggestion-item:last-child {
    border-bottom: none;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .result-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .result-item:last-child {
    border-bottom: none;
  }
  
  .result-item a {
    color: blue;
    text-decoration: none;
  }
  
  .result-item p {
    color: #666;
  }
  
  button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
  } */
  .search-container {
    font-family: Arial, sans-serif;
    color: #444;
    max-width: 600px;
    margin: 20px auto;
  }
  
  .search-container h1 {
    color: #4285f4;
    font-size: 24px;
  }
  
  .search-container form {
    display: flex;
    margin-bottom: 10px;
  }
  
  .search-container input[type="text"] {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #dcdcdc;
    border-right: none;
  }
  
  .search-container button {
    padding: 8px 16px;
    background-color: #4285f4;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .search-container button:hover {
    background-color: #357ae8;
  }
  
  .suggestions {
    position: absolute;
    background: white;
    width: calc(100% - 2px); /* Subtract borders */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border: 1px solid #dcdcdc;
    border-top: none;
  }
  
  .suggestion-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #dcdcdc;
  }
  
  .suggestion-item:last-child {
    border-bottom: none;
  }
  
  .suggestion-item:hover {
    background-color: #f6f6f6;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .result-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  
  .result-item a {
    color: #1a0dab;
    text-decoration: none;
    font-weight: bold;
  }
  
  .result-item a:hover {
    text-decoration: underline;
  }
  
  .result-item p {
    color: #545454;
    margin: 5px 0;
  }
  
  button {
    margin-right: 10px;
    padding: 5px 10px;
    border: 1px solid #dcdcdc;
    background: #f8f8f8;
    cursor: pointer;
  }
  
  .bing-icon {
    height: 30px;  
    width: auto;  
    margin-right: 8px;  
    vertical-align: middle;  
}

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  button:hover:not(:disabled) {
    background: #4285f4;
  }
   